import React from "react"
import { Layout, Head, Breadcrumbs, ContactForm, Persons } from "@common"
import { useStaticQuery, graphql } from "gatsby"

const Contact = ({ pageContext }) => {
  const { page } = pageContext

  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        pageBy(uri: "kontakt") {
          ACF_contact {
            contact {
              title
              text1
              person1
              personimg1
              person2
              personimg2
              text2
            }
          }
        }
      }
    }
  `)

  const { contact } = WP.pageBy.ACF_contact
  const { title, text1, text2 } = contact

  return (
    <Layout title={page.title}>
      <Head
        seo={pageContext.page.seo}
        siteMetaData={pageContext.page.siteMetaData}
      />
      <div className="page">
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col" />
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8">
                <Breadcrumbs
                  elements={[
                    {
                      label: page.title,
                      url: `/${page.slug}/`,
                    },
                  ]}
                />
                <h3>{title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: text1,
                  }}
                ></div>
                <Persons />
                <p>{text2}</p>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
